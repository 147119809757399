import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, switchMap, from } from 'rxjs';
import { FleetUser } from '../types';
import { StorageService } from 'src/app/services/storage.service';
import { Contact } from 'src/app/transfers/model/transfer.model';

interface WithToken<T> {
  (token: string): Observable<T>;
}

@Injectable({
  providedIn: 'root',
})
export class SalesforceClientService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) {}

  private getToken() {
    return from(this.storageService.get('APEX_TOKEN'));
  }

  private withToken<T>(callback: WithToken<T>) {
    return this.getToken().pipe(switchMap(callback));
  }

  // TODO: refactor
  fetchCurrentUser() {
    return this.withToken((token) =>
      this.http.get<FleetUser>(path`/v2/users/current`, getOptions(token)),
    );
  }

  request<T>(method: string, url: string, options?: any) {
    return this.withToken((token) => {
      const actualUrl = `${environment.salesforceUrl}${url}`;

      const actualOptions = {
        ...getOptions(token),
        ...options,
      };

      return this.http.request<T>(
        method,
        actualUrl,
        actualOptions,
      ) as Observable<T>;
    });
  }
}

function path(strings: TemplateStringsArray) {
  const path = strings[0];
  return `${environment.salesforceUrl}${path}`;
}

function getOptions(token: string) {
  return { headers: { token: `${token}` } };
}
